import React from 'react';
import './SpecializationAreas.css';

const SpecializationAreas = () => {
  const categories = [
    { name: "Web Development", iconClass: "fas fa-code" },
    { name: "App Development", iconClass: "fas fa-mobile-alt" },
    { name: "Machine Learning", iconClass: "fas fa-robot" },
    { name: "Data Science", iconClass: "fas fa-database" },
    { name: "Cloud Computing", iconClass: "fas fa-cloud" },
    { name: "Generative AI", iconClass: "fas fa-brain" },
    { name: "Cybersecurity", iconClass: "fas fa-shield-alt" },
    { name: "UI/UX Design", iconClass: "fas fa-pencil-ruler" }
  ];

  return (
    <section className="specialization-areas">
      <div className="section-header">
        <h2>Areas of Specialization</h2>
        <p>Find the perfect match for your project needs in various innovative fields.</p>
      </div>
      <div className="categories">
        {categories.map((category, index) => (
          <div className="category" key={index}>
            <i className={`category-icon ${category.iconClass}`}></i>
            <h3>{category.name}</h3>
          </div>
        ))}
      </div>
    </section>
  );
};

export default SpecializationAreas;
