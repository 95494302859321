import React, { useState } from 'react';
import './registration.css';

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    studentid: '', // This will be set to a base36-encoded timestamp on submit
    name: '',
    phno: '',
    email: '',
    yearofstudy: '',
    college: '',
    domain: 'Web Development',
  });

  const formAction = 'https://s76pceu2e1.execute-api.ap-southeast-2.amazonaws.com/registrationdata';

  // Generate a base36 encoded ID
  const generateStudentId = () => {
    const timestamp = Date.now();
    return timestamp.toString(36); // Convert timestamp to base36
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // Set studentid to base36-encoded current timestamp
    const uniqueId = generateStudentId();
    const requestBody = {
      studentid: uniqueId,
      name: formData.name,
      phno: formData.phno,
      email: formData.email,
      yearofstudy: formData.yearofstudy,
      college: formData.college,
      domain: formData.domain,
    };

    console.log("Payload being sent to the API:", requestBody);

    try {
      const response = await fetch(formAction, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody), // Send requestBody as JSON
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log('Success:', responseData);
      alert('Registration successful! Complete fee payment!');

      // Redirect to the payment page
      window.location.href = "https://pages.razorpay.com/pl_OToY2xBUXGt4JN/view";

    } catch (error) {
      console.error('Error during registration:', error);
      alert('Registration failed!');
    }
  };

  return (
    <div className="registration-form-container">
      <h2>Registration Form</h2>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phno">Phone Number:</label>
          <input
            type="tel"
            id="phno"
            name="phno"
            pattern="[+]{0,1}[0-9]{10,13}"
            value={formData.phno}
            onChange={handleChange}
            required
          />
          <small>Format: +1234567890</small>
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="yearofstudy">Year of Study:</label>
          <select
            id="yearofstudy"
            name="yearofstudy"
            value={formData.yearofstudy}
            onChange={handleChange}
            required
          >
            <option value="">Select Year</option>
            <option value="1">Fresher (1st Year)</option>
            <option value="2">Sophomore (2nd Year)</option>
            <option value="3">Junior (3rd Year)</option>
            <option value="4">Senior (4th Year)</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="college">College:</label>
          <input
            type="text"
            id="college"
            name="college"
            value={formData.college}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="domain">Domain:</label>
          <select
            id="domain"
            name="domain"
            value={formData.domain}
            onChange={handleChange}
            required
          >
            <option value="Web Development">Web Development</option>
            <option value="Full Stack Development">Full Stack Development</option>
            <option value="Android Development">Android Development</option>
            <option value="Machine Learning">Machine Learning</option>
            <option value="Data Science">Data Science</option>
            <option value="Web Development Advanced">Web Development Advanced</option>
            <option value="App Development">App Development</option>
            <option value="Generative AI">Generative AI</option>
            <option value="Cybersecurity">Cybersecurity</option>
            <option value="Blockchain Development">Blockchain Development</option>
            <option value="DevOps">DevOps</option>
            <option value="Cloud Computing">Cloud Computing</option>
          </select>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default RegistrationForm;
