import React from 'react';
import './Roadmap.css';

const Roadmap = () => {
  const steps = [
    { name: "Register", iconClass: "fas fa-user-plus", description: " Create an account to get started on your journey to building and selling projects." },
    { name: "Select Domain", iconClass: "fas fa-list", description: "Choose your area of interest, such as web development, mobile apps, or data science." },
    { name: "Get Project Details", iconClass: "fas fa-file-alt", description: "Access project specifications, guidelines, and resources to kickstart your work." },
    { name: "Complete Project and Earn Certificate", iconClass: "fas fa-award", description: "Work on the core parts of the project, then submit for review and earn a certificate." },
    { name: "Upgrading and Marketplace Posting", iconClass: "fas fa-store", description: "Our team will refine your project and post it on the marketplace, where you can earn revenue when it’s sold." }
  ];

  return (
    <div className='roadmap_component'>
    <section className="roadmap">
        
      <div className="section-header">
        <h2 className='heading'>Project Roadmap</h2>
        <p>Follow these steps to build, refine, and sell your projects on our marketplace.</p>
      </div>
      <div className="steps">
        {steps.map((step, index) => (
          <div className="step" key={index}>
            <i className={`step-icon ${step.iconClass}`}></i>
            <h3>{step.name}</h3>
            <p>{step.description}</p>
          </div>
        ))}
      </div>
    </section>
    </div>
  );
};

export default Roadmap;
