import React from 'react';
import { Link } from 'react-router-dom';
import './InternshipWrapper.css';

const InternshipWrapper = () => {
  return (
    <div className="internship-wrapper">
      <section>
      <div className="category">
        <Link to="https://rzp.io/l/iXBtvRvhpI" className="category-link">
          Solo  <span className="category-description"> Independent projects for self-paced learning</span>
        </Link>
      </div>
      <div className="category">
        <Link to="https://rzp.io/rzp/sEvt930" className="category-link">
          Team  <span className="category-description"> Collaborative projects with peer involvement</span>
        </Link>
      </div>
      </section>
    </div>
  );
};

export default InternshipWrapper;
