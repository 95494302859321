import React from 'react';
import './FeaturedProjects.css';
import banking from '../../components/project images/banking_app.jpg'
import chatbot from '../../components/project images/chatbot.jpg'
import commerce from '../../components/project images/e-commerce.jpg'
import learning from '../../components/project images/e-learning.jpg'
import fitness from '../../components/project images/fitness.jpg'
import grocery from '../../components/project images/grocery.jpg'
import hospital from '../../components/project images/hospital.jpg'
import realestate from '../../components/project images/realestate.jpg'
import social from '../../components/project images/social_media_app.jpg'
import travel from '../../components/project images/travel.png'
import weather from '../../components/project images/weather.jpg'
import restaurant from '../../components/project images/restaurant.png'

const FeaturedProjects = () => {
  const projects = [
    {
      name: "John Doe",
      title: "E-Commerce Platform",
      description: "Innovative e-commerce platform with advanced features.",
      image: commerce,
      url: "https://github.com/user/project1",
      linkedin: "https://linkedin.com/in/johndoe",
      github: "https://github.com/user",
      rating: 4.8,
      price: "$150"
    },
    {
      name: "Jane Smith",
      title: "AI Chatbot",
      description: "Chatbot utilizing machine learning to interact with users.",
      image: chatbot,
      url: "https://github.com/user/project2",
      linkedin: "https://linkedin.com/in/janesmith",
      github: "https://github.com/user",
      rating: 4.5,
      price: "$200"
    },
    {
      name: "Alice Johnson",
      title: "Mobile Banking App",
      description: "Secure mobile app for managing banking needs.",
      image: banking,
      url: "https://github.com/user/project3",
      linkedin: "https://linkedin.com/in/alicejohnson",
      github: "https://github.com/user",
      rating: 4.7,
      price: "$250"
    },
    {
      name: "Bob Brown",
      title: "Social Media Platform",
      description: "Modern social media platform with unique features.",
      image: social,
      url: "https://github.com/user/project4",
      linkedin: "https://linkedin.com/in/bobbrown",
      github: "https://github.com/user",
      rating: 4.9,
      price: "$180"
    },
    {
      name: "Sara Wilson",
      title: "Healthcare Management System",
      description: "Comprehensive management system for health care facilities",
      image: hospital,
      url: "https://github.com/user/project5",
      linkedin: "https://linkedin.com/in/sarawilson",
      github: "https://github.com/user",
      rating: 4.6,
      price: "$220"
    },
    {
      name: "Michael Lee",
      title: "E-Learning Platform",
      description: "Interactive platform for online education.",
      image: learning,
      url: "https://github.com/user/project6",
      linkedin: "https://linkedin.com/in/michaellee",
      github: "https://github.com/user",
      rating: 4.4,
      price: "$170"
    },
    {
      name: "Emily Clark",
      title: "Travel Booking System",
      description: "Efficient system for booking travel and accommodations.",
      image: travel,
      url: "https://github.com/user/project7",
      linkedin: "https://linkedin.com/in/emilyclark",
      github: "https://github.com/user",
      rating: 4.8,
      price: "$190"
    },
    {
      name: "James King",
      title: "Online Grocery Store",
      description: "Convenient platform for online grocery shopping.",
      image: grocery,
      url: "https://github.com/user/project8",
      linkedin: "https://linkedin.com/in/jamesking",
      github: "https://github.com/user",
      rating: 4.7,
      price: "$160"
    },
    {
      name: "Laura Scott",
      title: "Fitness Tracker App",
      description: "Mobile app to track fitness and health activities.",
      image: fitness,
      url: "https://github.com/user/project9",
      linkedin: "https://linkedin.com/in/laurascott",
      github: "https://github.com/user",
      rating: 4.6,
      price: "$210"
    },
    {
      name: "Daniel White",
      title: "Real Estate Portal",
      description: "Comprehensive portal for real estate listings and management.",
      image: realestate,
      url: "https://github.com/user/project10",
      linkedin: "https://linkedin.com/in/danielwhite",
      github: "https://github.com/user",
      rating: 4.5,
      price: "$240"
    },
    {
      name: "Olivia Brown",
      title: "Restaurant Management System",
      description: "Efficient system for managing restaurant operations.",
      image: restaurant,
      url: "https://github.com/user/project11",
      linkedin: "https://linkedin.com/in/oliviabrown",
      github: "https://github.com/user",
      rating: 4.7,
      price: "$230"
    },
    {
      name: "Chris Evans",
      title: "Weather Forecast App",
      description: "Accurate weather forecasting app with user-friendly interface.",
      image: weather,
      url: "https://github.com/user/project12",
      linkedin: "https://linkedin.com/in/chrisevans",
      github: "https://github.com/user",
      rating: 4.8,
      price: "$180"
    }
  ];

  return (
    <section className="featured-projects">
      <div className="section-header">
        <h2>Featured Projects</h2>
        <p>Discover some of the most exciting projects our interns have completed.</p>
      </div>
      <div className="project-cards">
        {projects.map((project, index) => (
          <div className="project-card" key={index}>
            <img src={project.image} alt={project.title} className="project-image" />
            <p className="title">{project.title}</p>
            <p className="description">{project.description}</p>
            <div className="project-info">
              <div className="project-details">
                <h3>{project.name}</h3>
                <div className="rating">
                  <span>⭐ {project.rating}</span>
                </div>
              </div>
              <div className="project-links-price">
                <div className="social-links">
                  <a href={project.linkedin} target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-linkedin"></i>
                  </a>
                  <a href={project.github} target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-github"></i>
                  </a>
                </div>
                <p className="price">{project.price}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FeaturedProjects;
