import React from 'react';
import FeaturedProjects from './FeaturedProjects';
import HowItWorks from './HowItWorks.jsx';
import SpecializationAreas from './SpecializationAreas';
import HeaderComponent from './HeaderComponent';


function Tradex() {
  return (
    <div className="App">
      <HeaderComponent/>
      <SpecializationAreas/>
      
      <FeaturedProjects />
      <HowItWorks />
    </div>
  );
}

export default Tradex;
