import React from 'react';
import './HeaderComponent.css';

const HeaderComponent = () => {
  return (
    <header className="tradex-header">
      <div className="welcome-message">Welcome!</div>
      <div className="banner-content">
        <h1>TechXchange Marketplace</h1>
        <p>
          Connect with talented interns & hire them for freelance projects in cutting-edge fields.
        </p>
        <div className="arrow-down"></div>
      </div>
    </header>
  );
};

export default HeaderComponent;
