import React from 'react'
import AboutUs from './Aboutus.jsx'
import FullBanner from '../../components/stop/FullBanner.jsx'
import SpecializationAreas from '../tradex/SpecializationAreas.jsx';

const Aboutusmain = () => {
  return (
    <div>
      <FullBanner buttonLink="/apply"
      component = {<SpecializationAreas/>}/>
      <AboutUs/>
    </div>
  )
}

export default Aboutusmain
