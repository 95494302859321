import React from 'react';
import { Link } from 'react-router-dom';
import './FullBanner.css'; // Import your CSS file for styling

const FullBanner = ({ buttonLink }) => {
  return (
    <div className="full-banner">
      <div className="banner-content">
        <h1 className="banner-heading">
          Empowering Future Innovators
          <div>
            <span className="highlight-text">Stop Studying, Start Working</span>
          </div>
        </h1>
        <p className="banner-text">
          Don't worry, you're just getting started! <br />
          We will help you with the rest of the project.
        </p>
        
        
        <Link to={buttonLink} className="banner-button">Explore Projects</Link>
      </div>
    </div>
  );
};

export default FullBanner;
